@import "react-big-calendar/lib/sass/styles"; // @import "react-big-calendar/lib/addons/dragAndDrop/styles";

.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: $event-padding;
  background-color: #f6effe;
  color: #a162f7;
  cursor: pointer;
  width: 100%;
  text-align: left;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: darken(#f6effe, 10%);
  }

  &:focus {
    outline: 5px auto #a162f7;
  }
}

.rbc-off-range-bg {
  background: #c4c4c41a;
}

.rbc-today {
  background-color: #c4c4c41a;
}
